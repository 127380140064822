import React from 'react'

const Channelcard = ({data, createChannel,imagePath}) => {
    
  return (
    <div className="channel-card flex items-start p-4 border rounded-md w-auto h-auto mx-4 sm:mx-2 my-2 md:my-4 flex-col relative">
        <div className="img-ct top-sec flex flex-row items-center">
            <div className="img-innet-ct">
                 <img className=" mb-3 rounded-full shadow-lg" src={data?.attributes?.ProfileImage?.data!=null ? imagePath+data?.attributes?.ProfileImage?.data.attributes.url : "/assets/images/app_dark_img.png"} alt="Bonnie image"/>
            </div>
            <h5 className="mb-1 text-[#000] ml-3 text-base md:text-lg font-bold text-left">{data?.attributes.Name}</h5>
            <div className="ml-10 sm:ml-4 sm:right-2 sm:top-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full bg-green-200 text-green-700 border">
              Free
            </div>
        </div>
       
        <div className="content-ct mt-4 h-full flex flex-col justify-between">
            <p className='description text-left font-semibold text-sm text-gray-400'>{data?.attributes.Description}</p>
            {data?.attributes?.tags?.data.length > 0 ? 
              <div className='flex flex-wrap text-gray-400'>
                  {data?.attributes?.tags?.data?.map((data) =>
                      <div className="mr-2 my-2 tagTitle inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full bg-white text-gray-400 border">
                        {data?.attributes?.Name}
                      </div>
                  )
                  } 
              </div>: 
            null  
            }
            <div className="flex mt-4 space-x-3 md:mt-4 w-full ">
                <a  className="start-convr-link w-full inline-flex cursor-pointer items-center justify-center px-4 py-3 text-xs font-medium text-center text-white bg-[#000] rounded-lg hover:bg-gray-800  dark:focus:ring-blue-800" onClick={(e)=>createChannel(data?.attributes.Name, data?.attributes.bot_id, "pdf", "chanel1", data?.attributes?.ProfileImage?.data?.attributes?.url ? imagePath+data?.attributes?.ProfileImage?.data?.attributes?.url:'https://static.sendbird.com/sample/cover/cover_14.jpg')}>
                  Chat Now <img className="ml-5" src="/assets/images/card-arrow.svg" alt="card-arrow-icon"/></a>
            </div>
        </div>
    </div>
  )
}

export default Channelcard