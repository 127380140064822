import React from 'react';
import Channelcard from './Channelcard';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth";
import { SENDBIRD_INFO } from '../constants/constants';
import { useNavigate, BrowserRouter as Router, Link, } from "react-router-dom";
import { auth } from "../firebase";
import ProfileIcon from './ProfileIcon';
import { Helmet } from "react-helmet-async";
import { useQuery } from '@tanstack/react-query';


const Botlist = () => {
  const navigate = useNavigate();
  let params = useParams()
  const [user] = useAuthState(auth);
  const [createChannel, userNameInputValue] = useOutletContext();
  const slug = params.slug
  
  const HandleBackBtnNavigate = () => {
    navigate('/');
  }

  const getCategoryAllBot = async () => {
    try {
      const usertoken = await user.getIdToken();
      const response = await axios.get(`${SENDBIRD_INFO.newBotUrl}/get_bots/${slug}`, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
          'Api-token': SENDBIRD_INFO.apiToken,
        },
      });

      const botData = response.data.data[0].attributes;

      return botData;
    } catch (error) {
      console.log(error.message + 'file-error');
      throw error;
    }
  }

  const { data: botData, isLoading } = useQuery({
    queryKey: ['bots', slug],
    queryFn: getCategoryAllBot,
    enabled: !!user,
  })

  if (isLoading) {
    return (
      <div className="loader-ct">
        <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader'></img>
      </div>
    )
  }

  return (
    <>
      <div className='lg:mx-5 bot-lib'>
        <Helmet>
          <title>{`Brainstormer - ${botData?.Name}`}</title>
          <meta name="description" content={`${botData?.Description}`} />
          <meta property="og:title" content={`Brainstormer - ${botData?.Name}`}></meta>
          <meta property="og:description" content={`${botData?.Description}`} />
        </Helmet>

        <div className="mb-logo-section">
          <img onClick={HandleBackBtnNavigate} className="" src="/assets/images/left-arrow-white.svg" alt="brainstromapp" />
          <h1 className='mb-heading sm:text-xl text-[#ffffff] font-bold px-5 my-3'>{botData.Name}</h1>
          {userNameInputValue ? <ProfileIcon userid={userNameInputValue.uid} username={userNameInputValue.displayName} /> : null}
        </div>
        <div className='hidden sm:flex  pt-5 pb-1 '>
          <Link to='/' className=''><img src="/assets/images/leftArrow.svg" alt="left-arrow-icon" onClick={HandleBackBtnNavigate} /></Link>
          <h2 className='text-2xl font-bold px-2 text-[#2C2D98] flex items-center'>
            {botData.Name}
          </h2>
        </div>

        <div className="wrapper sm:mx-2 mt-2 sm:mt-0 pt-2 sm:pt-0">
          <p className='text-left text-base sm:px-2 px-[20px] pt-3 pb-2 text-gray-400 '>{botData.Description}</p>
          <div className="bot-content-ct flex flex-wrap w-full bg-white rounded-lg justify-start mt-2 pb-14">
            {botData?.bots?.data.map((item, i) =>
              <Channelcard data={item} key={i} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />
            )}
          </div>
        </div>
      </div>
    </>
  )


}

export default Botlist