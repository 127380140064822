import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryCard from './CategoryCard';
import ProfileIcon from './ProfileIcon';
import { SENDBIRD_INFO } from '../constants/constants';
import {Helmet} from "react-helmet";
import Channelcard from './Channelcard';
import { useQuery } from '@tanstack/react-query';

const Categorylist = ({ userNameInputValue, createChannel }) => {

    const [existingOpt, setExistingOpt] = useState([]);
    const [active, setActive] = useState(0);
    const [apiResult, setApiResult] = useState(false);

    useEffect(()=>{
      getAllBot();
    },[]);

    function getAllBot(){
      setActive(0);
      axios({
        url:`${SENDBIRD_INFO.newBotUrl}/get_bot_categories`,
        method: 'get',
        headers: {
          'Api-token': SENDBIRD_INFO.apiToken
        }
      })
      .then((res) => res)
      .then((response) => {
          
          if(response.data.data.length === 0){
            axios({
              url:`${SENDBIRD_INFO.newBotUrl}/get_all_bots`,
              method: 'get',
              headers: {
                'Api-token': SENDBIRD_INFO.apiToken
              }
            })
            .then((res) => res)
            .then((response) => {
                setApiResult(true)
                setExistingOpt(response.data.data);
              })
              .catch((err) => {
                console.log(err.message+"file-error");
            }); 
          }
          else{
            setExistingOpt(response.data.data);
          }
      })
      .catch((err) => {
          console.log(err.message+"file-error");
      }); 
    }

  // const { data, isLoading } = useQuery({
  //   queryKey: ['categories'],
  //   queryFn: getAllBot
  // })

  const closeChannel = () => {
    document.querySelector('.channel').style.zIndex = '0';
  }

  return (
    <div className='lg:mx-5 bot-lib'>
      <Helmet>
        <title>Brainstormer Explore Bots</title>
      </Helmet>
        <div className="mb-logo-section">
            <img onClick={closeChannel} className="back-arrow w-[24px]" src="/assets/images/white_Chat.svg" alt="brainstromapp"/>
            <img className="w-auto brainstrom-logo" src="/assets/images/Zoo_Logo_White_2.svg" alt="brainstromapp"/>
            {userNameInputValue ? <ProfileIcon userid={userNameInputValue.uid} username={userNameInputValue.displayName}/> : null }
        </div>

        <div className="wrapper mx-4 sm:mx-2 mt-2 pt-2">
          <div className={`bot-content-ct flex flex-wrap w-full bg-white rounded-lg justify-start mt-4 sm:mt-2 pb-14 ${ apiResult ? 'single-bot':''}`}>
            <h1 className='text-2xl font-bold px-0 sm:px-2  pb-2 text-[#0] flex items-center'>
              Explore</h1>
            <p className='sub-heading text-left text-base sm:px-2 px-[30px] pt-3 pb-2 text-gray-400 '>Step into the future with our diverse range of AI Bots, specifically designed to support and enhance your personal and professional life. Here, you’ll discover bots for every occasion, each one ready to provide expert advice in areas from personal wellness and learning to business strategy and beyond.</p>
           
                
                {existingOpt.length?existingOpt.map((item,i) =>

              <>
                  {apiResult ? 
                    <Channelcard data={item} key={i} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />  
                    :
                    <CategoryCard data={item} key={i} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />
                  }
                  </>

                ) :
                <div className="loader-ct">
                            <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader'></img>
                         
                        </div>
              
                }
            </div>
        </div>

    </div>
  )
}

export default Categorylist